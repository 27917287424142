.c-user {
    border-bottom: solid 1px #ffffff;
	padding-left: 4px;
	display: flex;
	justify-content: space-between;
    align-items: center;
    
    &--first {
        border-top: solid 1px #ffffff;
    }
    &__title {
        @include f(14px,$c-font,700);
    }
}
