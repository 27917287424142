.c-btn {
	transition: all .25s ease .1s;
	display: inline-block;
	min-width: 125px;
	outline: none;
	box-shadow: none;
	margin: 0;
	border: none;
	padding: 0 15px;
	cursor: pointer;
	@include f(15px,$c-white!important,700,25px);
	text-align: center;
	background: $c-theme-btn;
	border-radius: 25px;
	margin-bottom: 15px;

	&:hover {
		color: $c-white;
		background: $c-theme-btn;
	}

	&--light {
		background: $c-theme-alt;
		color:$c-white;
		&:hover, &:focus {
			background: lighten($c-theme-alt,7%);
			color:$c-theme;
		}
	}

	&--white {
		background: $c-white;
		color: $c-theme;
		&:hover, &:focus {
			background: lighten($c-theme-alt,7%);
			color:$c-theme;
		}
	}

	&--sm-br {
		border-radius:5px;
	}

	&--big {
		padding: 15px;
		@include f(17px);
	}
	@include mq($screen-xs) {
		@include f(13px);
		&--big {
			@include f(16px);
		}
	}
}
