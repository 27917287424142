.u-full-width { width: 100%!important; }

.u-no-pb { padding-bottom: 0 !important; }
.u-no-pt { padding-top: 0 !important; }
.u-no-pl { padding-left: 0!important; }
.u-no-pr { padding-right: 0!important; }

.u-no-mt { margin-top: 0 !important; }
.u-no-mb { margin-bottom: 0 !important; }
.u-no-ml { margin-left: 0 !important; }
.u-no-mr { margin-right: 0 !important; }

.u-bg {background-color:$c-bg-gray!important;}

.u-weight-300 {font-weight: 300 !important;}