.c-product {
  $mod:'.c-product';
  text-align: center;
  border: 1px solid #e9ecef;
  margin-top: 10px;
  
  &__image {
    margin-bottom: 15px;
    position: relative;
    
    img {
      width: 100%;
      
    }
  }

  //&__caption {
  //  position: absolute;
  //  top:15px;
  //  left:15px;
  //  display: inline-block;
  //  background: $c-theme-alt;
  //  @include f(11px,$c-white,700);
  //  padding:5px 10px;
  //}

  &__title {
    
    
    @include f(17px,#393939,700);
    margin-bottom: 7px;
  }

  &__price {
    @include f(17px,$c-theme,400,0);
    margin-top: 7px;
    margin-bottom: 10px;
  }

  &__link {

  }

}
