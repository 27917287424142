.l-newsletter {
  padding: 50px 0;
  background: $c-bg;
  img {
    vertical-align: bottom;
  }
  &__caption {
    display: inline-block;
    h2 {
      margin-bottom: 10px;
    }
    h3 {
      @include f(16px,$c-white,0,0);
    }

  }
  @include mq($screen-md) {
    &__caption {
      margin-bottom: 15px;
    }
  };
}
