.p-home {

  
  &__boxes {
    margin-bottom:30px;
    .item {
      position: relative;
      @include f(16px,$c-theme,700,normal);
      @include flexAlign(center,flex-start);
      height:60px;
      padding-left:70px;
    }
    img {
      position: absolute;
      top:0;
      left:0;
    }
  }

  &__box {
    padding:50px;
    background: $c-white;
    //margin-bottom:20px;
    min-height:330px;

    .text {
      font-weight:600;
      margin:10px 0;
    }

    &--bg {
      background: url(#{$img}/slider/2.jpg);
      background-position: center;
      background-size: cover;
      .text {
        margin:40px 0;
      }
    }

    &--light {
      background: $c-theme-alt;
      min-height: 0;
      .text {
        margin:0;
        font-weight:400;
      }
    }

    &-date {
      @include f(13px,$c-theme-alt,600);
    }

    @include mq($screen-sm) {
      padding:20px;
      &--light {
        .text { margin-bottom: 10px; }
      }
    }
  }
}