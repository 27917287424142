
.cpr {

  padding-top: 1.5rem;
  $_logo-height: 14px;
  margin-top: 5px;
  border-top: 1px solid $c-border;
  padding-bottom: 40px;
  @include f(12px,$c-font-footer,400,($_logo-height * 1.5));
  background: $c-white;

  @include mq($screen-sm) {
    text-align: center;
  }

  a {
    color: $c-theme;
    font-weight: 700
  }

  &__right {
    text-align: right;
    @include mq($screen-sm) {
      padding: 8px 0 0;
      text-align: center;
    }
  }

  &__link {
    display: inline-block;
    vertical-align: baseline;
    margin-left: 7px;
    font-size: 0;
    line-height: 0px;

    img {
      vertical-align: baseline;
      height: $_logo-height;
    }
  }
  &__line {
    padding:5px 0px 35px 0px;
   color: #eeeeee;
  }
}
