.main-nav {
  position: relative;

  @include mq($screen-lg) {
    padding:0;
  }
  ul { margin: 0; padding: 0; font-size: 0; }
  ul li { position: relative; vertical-align: bottom; list-style: none; }
  ul li a { position: relative; display: block; cursor: pointer; }
  ul ul { transition: all .25s ease .4s; position: absolute; left: 0; z-index: 211; min-width: 100%; visibility: hidden; opacity: 0; }

  // I level
  > ul { @include flexAlign(initial,space-between);}
  > ul > li {
    display: inline-block;
  }
  > ul > li > a {
    @include f(14px,$c-white,700,50px,uppercase);

    &:hover, &:focus, &.current {
      color: $c-theme;
    }
  }

  // II level
  > ul > li > ul { left: 0; text-align: left;
    padding-top: 10px;
    background-color: $c-theme-alt;}

  > ul > li > ul > li { display: block; background: $c-white; }
  > ul > li > ul > li > a {
    padding: 15px;
    white-space: nowrap;
    @include f(13px,$c-font,400,1em);

    &:hover, &:focus, &.current {
      color: $c-white;
      background: $c-theme-alt;
    }
  }

  // III level
  > ul > li > ul > li > ul {
    right: 0;
    top:0;
    transform: translateX(100%);
    text-align: left;
  }
  > ul > li > ul > li > ul > li { display: block; background: $c-white; }
  > ul > li > ul > li > ul > li > a {
    padding: 15px;
    white-space: nowrap;
    @include f(13px,$c-font,400,normal);

    &:hover, &:focus, &.current {
      color: $c-white;
      background: $c-theme-alt;
    }
  }

  ul > li:hover > ul { visibility: visible; opacity: 1; }

  &__follower {
    transition: all .4s ease .2s;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0;
    @include size(30px,8px);
    background: $c-theme;
  }
}

.sidr { display: none; }
.sidr-toggle { display: none; }

@include mq($screen-sidr) {
  @include createSidr($_width: 300px, $_padding: 15px, $_background: $c-white, $_sidr-toggle-size: 50px);
  @include createSidrToggle($_size: 50px, $_background: rgba($c-theme,.8), $_offset: 15px);

  .sidr-inner {
    .main-nav { padding: 0;
      ul { display: block; }
      ul li a {  }
      ul ul { transition: none; visibility: visible; opacity: 1; position: static; }

      // I level
      > ul {  }
      > ul > li { display: block; margin: 0; }
      > ul > li > a {
        height: 50px;
        margin: 0;
        padding-top:8px;
        
       
        border-top: 1px solid #ffffff;
        @include f(14px,$c-theme,700,40px,uppercase);

        &:hover, &:focus, &.current {
          background: $c-bg;
          
        }
      }

      // II level
      > ul > li > ul { position: static; padding: 0 10px 10px; background-color: transparent;}
      > ul > li > ul > li { display: block; width: 100%; padding: 0; }
      > ul > li > ul > li > a {
        @include f(14px,$c-font,400,1em); padding: 7px 15px;

        &:hover, &:focus, &.current {
          color: $c-white;
          background: $c-theme;
        }
      }
      // III level
      > ul > li > ul > li > ul { position: static; padding: 0 15px 15px; transform:none; }
      > ul > li > ul > li > ul > li { display: block; width: 100%; padding: 0; }
      > ul > li > ul > li > ul > li > a {
        @include f(14px,$c-font,400,1em); padding: 7px 15px;

        &:hover, &:focus, &.current {
          color: $c-white;
          background: $c-theme;
        }
      }

      &__follower {
        display: none;
      }
    }
  }

}
