.l-chat {
  display: flex;
  flex-direction: column;
  $_c-left:#347ab6;
  $_c-right:#949494;
  &__messages {
    max-height:50vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }

  &__message {
    padding: 1rem;
    border-radius: 15px;
    margin:5px 0;
    @include f(12px,$c-white,400,0,0,$ff-alt);
    background-color: $_c-right;
    display: inline-block;
    margin-left: auto;
    &--left {
      margin-right: auto;
      margin-left:initial;
      background-color: $_c-left;
    }
  }

  &__text {
    @include f(12px,#949494,400,0,0,$ff-alt);
    text-align:center;
    margin:1.5rem 0;
  }

  &__input {

  }

  button {
    min-width:0;
    border-radius:5px;
    padding: 5px 15px;
  }
}