.slider {
  $_nav-offset:20px;
  $_nav-color:$c-black;
  $_content-padding:80px 20px;
  $_title-fs:30px;
  $_title-color:$c-white;
  $_text-fs:20px;
  $_text-color:$c-white;

  $_dots-bg: $c-theme-alt;
  $_dots-size: 10px;
  $_dots-active-bg: $c-theme;

  &__inner {
    position: relative;

    .prev, .next {
      @include flexAlign();
      @include f(30px,$_nav-color,700);
      @include size(50px);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 3;
    }
    .prev {
      left: $_nav-offset;
    }
    .next {
      right: $_nav-offset;
    }
    @include mq($screen-lg) {
      .prev {
        left: $_nav-offset/2;
      }
      .next {
        right: $_nav-offset/2;
      }
    };
    @include mq($screen-sidr) {
      .prev, .next {
        display: none;
      }
    };
  }

  .slide {
    background-position: center;
    background-size: cover;
    position: relative;

    &__content {
      padding:$_content-padding;
    }

    &__title {
      @include f($_title-fs,$_title-color,700);
    }

    &__caption {
      @include f($_text-fs,$_text-color,500,1.5em);
      margin-bottom: 30px;
    }

    @include mq($screen-sm) {
      &__content {
        padding: 20px;
      }

      &__title {
        @include f($_title-fs*3/4);
      }

      &__caption {
        @include f($_text-fs*3/4);
        margin-bottom: 15px;
      }

    }
  }

  .owl-dots {
    position: absolute;
    bottom: 60px;
    left: 80px;

    .owl-dot span {
      transition: $def-transition;
      @include size($_dots-size);
      border: none;
      border-radius: 50%;
      margin: 0 5px;
      background: $_dots-bg;
    }

    .owl-dot span:hover,
    .owl-dot:hover span,
    .owl-dot.active span {
      background: $_dots-active-bg;
    }

    @include mq($screen-sm) {
      left:50%;
      transform: translateX(-50%);
      bottom:10px;
    }
  }
}
