// Sidr components


@mixin createSidr($_width: 300px, $_padding: 15px, $_background: #ededed, $_sidr-toggle-size: 50px) {
  .sidr {
    $_headline-height: $_sidr-toggle-size + $_padding;

    display: none;
    overflow-x: hidden;
    position: fixed;
    top: 80px;
    text-align: center;
    z-index: $z-index-sidr;
    @include size(100%);
    padding-top: 34px;
    border-right: 1px solid rgba($c-black,.3);
    box-shadow: 3px 0 12px rgba($c-black,.3);
    background-color: #ededed;

    &:before { 
      $_height: 24px;
      content: "Twoje konto";
      padding-bottom:35px;
      text-align: center;
      top: $_headline-height - $_height + 3px;
      @include f($_height,$c-theme,400,$_height);
    }

    &.left { left: -$_width; right: auto; &:before { left: $_sidr-toggle-size + 2*$_padding; } }
    &.right { left: auto; right: -$_width; }

    .sidr-inner {
      padding: $_padding 0;


      &:first-of-type { margin-top: 15px; }
    }
  }
}

@mixin createSidrToggle($_size: 50px, $_background: rgba($c-theme,0.6), $_offset: 15px) {
  .sidr-toggle { 
    transition: opacity .3s;
    display: block;
    position: fixed;
    z-index: $z-index-sidr-toggle;
    top: $_offset;
    @include size($_size);
    padding: ($_size * .46) ($_size * .2);
    background: $_background;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;

    &.left { left: $_offset; }
    &.right { right: $_offset; }

    &.is-ready {
      opacity: 1;
      visibility: visible;
    }

    @include createTransformicon('tr-lines','tr-x',($_size * .6),#fff);
  }
}