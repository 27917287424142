.title {
	@include f(24px,$c-font-content,400);

	&__caption {
		@include f(14px,$c-theme,600,1.7em);
		margin:15px 0;
	}
}

.text-black { color: $c-black!important; }
.text-white { color: $c-white!important; }
.text-theme { color: $c-theme!important; }
.text-theme-alt { color: $c-theme-alt!important; }
.text-blue { color: $c-theme-btn!important; }
.text-bold { font-weight: 700!important; }

.text {
	margin-bottom: 15px;
	@include f(14px,$c-font,400);

	a:hover {
		color: $c-theme;
	}

	img {
		margin: 0 25px 20px 0;
	}

	ul {
		padding-left: 45px;

		li {
			$_list-style-size: 10px;

			display: block;
			position: relative;
			margin-bottom: 10px;

			&:before {
				content: "";
				position: absolute;
				left: -20px;
				top: calc(50% - (#{$_list-style-size} / 2));
				@include size($_list-style-size);
				border-radius: 50%;
				background: $c-theme;
			}
		}
	}

	table {
		width: 100%;
		border-spacing: 0px;
		border-collapse: separate;

		tr {
			th {
				padding: 10px 15px;
				color: $c-white;
				background: $c-theme;
			}

			td {
				border: 1px solid $c-theme;
				padding: 10px 15px;
			}
		}
	}
}
