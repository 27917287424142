@function validateParameters($parameters) {

  $_height: nth($parameters, 1);
  $_border: nth($parameters, 2);
  $_border-color-focus: nth($parameters, 3);
  $_border-radius: nth($parameters, 4);
  $_padding: nth($parameters, 5);
  $_font-size: nth($parameters, 6);
  $_color: nth($parameters, 7);
  $_background: nth($parameters, 8);
  $_required-color: nth($parameters, 9);

  @if $_height == 0 { $_height: $form-height; }
  @if $_border == 0 { $_border: $form-border; }
  @if $_border-color-focus == 0 { $_border-color-focus: $form-border-color-focus; }
  @if $_border-radius == 0 { $_border-radius: $form-border-radius; }
  @if $_padding == 0 { $_padding: $form-padding; }
  @if $_font-size == 0 { $_font-size: $form-font-size; }
  @if $_color == 0 { $_color: $form-color; }
  @if $_background == 0 { $_background: $form-background; }
  @if $_required-color == 0 { $_required-color: $form-required-color; }


  $parameters: (
          $_height, $_border, $_border-color-focus, $_border-radius, $_padding, $_font-size, $_color, $_background, $_required-color
  );

  @return $parameters;
}

@mixin createForm($parametersIn) {

  $parameters: validateParameters($parametersIn);

  $_height: nth($parameters, 1);
  $_border: nth($parameters, 2);
  $_border-color-focus: nth($parameters, 3);
  $_border-radius: nth($parameters, 4);
  $_padding: nth($parameters, 5);
  $_font-size: nth($parameters, 6);
  $_color: nth($parameters, 7);
  $_background: nth($parameters, 8);
  $_required-color: nth($parameters, 9);

  $default-transition: all .25s ease;

  position: relative;
  font-size: $_font-size;

  .form-group {
    //@include createBootstrapSelect($_height, $_border, $_border-radius, $_border-color-focus, $_padding, $_font-size, $_color, $_background);
    @include createSelect($_height, $_border, $_border-radius, $_border-color-focus, $_padding, $_font-size, $_color, $_background);
    @include createNumberField();
    @include createCheckboxes(24px, $_background, $_border, $_color);
    @include createFileButton($_height, $_padding, $c-theme, 16px, $c-white);

    position: relative;

    &--inline {
      label {
        line-height:$_height;
      }
    }

    &--approve {
      input[type="checkbox"] + label {
        @include f(14px,$c-black,300,0.9em);
      }
    }
  }

  label {
    display: inline-block;
    margin: 0 0 7px;
    @include f($_font-size,$c-font,700,$_height);
  }

  input,
  textarea,
  select {
    transition: all 0.25s ease;
    height: $_height;
    border: $_border;
    border-radius: $_border-radius;
    padding: 0 $_padding;
    @include f($_font-size,$_color,400,$_height);
    @include placeholder($_color);
    background: $_background;

    &:focus {
      border-color: $_border-color-focus;
    }
  }

  textarea {
    resize: none;
    height: 150px;
    padding: $_padding;
    line-height: initial;
  }

  .required > label:after {
    content: " *";
    color: $_required-color;
  }

  .has-error {
    input,
    input[type="radio"] + label span,
    input[type="checkbox"] + label span,
    textarea,
    select {
      border-color: $c-danger;
    }

    input[type="radio"] + label,
    input[type="checkbox"] + label {
      color: $c-danger;
    }

    .form-error-desc {
      padding: 5px 15px;
      @include f(12px,$c-white,400);
      text-align: left;
      background: $c-danger;
    }
  }
}