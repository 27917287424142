.header {

  background: $c-theme;
  padding:1.5rem;
  position: fixed;
  top: -80;
  right:0;
  left: 0;
  z-index: 1;

 &__inner {
   display: flex;
   justify-content: space-between;
 }
 &__box{
   height: 80px;
   
 }

}