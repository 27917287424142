@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700|Lato:400&subset=latin-ext');
html, body {
	font-family: $ff-default, sans-serif;
	@include f($default-font-size,$c-font);
	  overflow-x: hidden;
	  background: $c-bg;
}

// .main-content {
	
// }

@include mq($screen-sidr) {
	.hidden-sidr {
		display: none!important;
	}
}

.visible-sidr {
	display: none!important;

	@include mq($screen-sidr) {
		display: block!important;
	}
}

