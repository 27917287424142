.form-standard {
  $_height: 45px;
  $_border: 1px solid $c-border;
  $_border-color-focus: $c-theme-alt;
  $_border-radius: 5px;
  $_padding: 15px;
  $_font-size: 13px;
  $_color: $c-font;
  $_background: $c-white;
  $_required-color: $c-danger;

  $parameters: (
          $_height, $_border, $_border-color-focus, $_border-radius, $_padding, $_font-size, $_color, $_background, $_required-color
  );

  @include createForm($parameters);

  .form-group {

  }
}

.form-search {
  $_height: 45px;
  $_border: 1px solid $c-border;
  $_border-color-focus: $c-theme;
  $_border-radius: 0;
  $_padding: 15px;
  $_font-size: 13px;
  $_color: #7c7c7c;
  $_background: transparent;
  $_side: right;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: $_height;

  $parameters: (
          $_height, $_border, $_border-color-focus, $_border-radius, $_padding, $_font-size, $_color, $_background, 0
  );

  @include createForm($parameters);


  input {
    padding-#{$_side}: $_height;
  }

  button[type="submit"] {
    position: absolute;
    top: 0;
    #{$_side}: 0;
    z-index: 2;
    text-align: center;
    padding:0;

    @include size($_height);
    background: transparent;
  }

  @include mq($screen-sidr) {
    width:100%;
  }


} //main search form


.form-sort {
  margin-bottom: 10px;
  display: inline-block;
  button.dropdown-toggle {
    background: transparent!important;
    outline: none!important;
    box-shadow: none!important;
    color: $c-black!important;
    border-radius: 0!important;
    border:none!important;
    &:focus {
      background: transparent!important;
      outline: none!important;
      box-shadow: none!important;
    }
    .bs-caret {
      display: none;
    }

    position: relative;
    &::after {
      content: '\f107';
      font-weight:900;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      font-family: "FontAwesome";
    }
  }

  .dropdown-menu {
    border-radius: 0;
    border: none;
    box-shadow: none;
    background: $c-bg;
    padding:0;
    ul {
      li {

        a {
          line-height: normal;
          margin: 0;

          &:focus {
            outline: none!important;
          }
          &:hover,&:focus {
            background-color: $c-theme;
            span {
              color:$c-white!important;
            }
          }
          span {
            margin:0;
            transition: all .25s ease .1s;
          }
        }
      }
    }
  }
} //sort products

.form-filters {
  $_height: 50px;
  $_border: none;
  $_border-color-focus: $c-theme-alt;
  $_border-radius: 0;
  $_padding: 15px;
  $_font-size: 15px;
  $_color: $c-theme;
  $_background: $c-white;

  $parameters: (
          $_height, $_border, $_border-color-focus, $_border-radius, $_padding, $_font-size, $_color, $_background, 0
  );

  @include createForm($parameters);

  .form-group--price {
    display: inline-block;
    input {
      width: 50px;
      display:inline-block;
    }
    label {
      text-transform: none;
      color:$c-theme;
      margin:0 5px;
    }
  }
  button.dropdown-toggle {

    .bs-caret {
      display: none;
    }

    position: relative;
    &::after {
      content: '\f107';
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      right: 30px;
      border:none;
      font-family: "FontAwesome";
      @include f(20px,$c-theme,900)
    }
  }
} //filter products